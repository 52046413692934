<template>
  <div class="footer">
    <div class="footer_nav">
        <div class="footer_nav_menu">
            <h3>新零售</h3>
            <p @click="changePage('/retail')">新零售云店</p>
            <p @click="changePage('/broadcast')">直播卖货</p>
            <p @click="changePage('/community')">社区团购</p>
            <p @click="changePage('/agent')">代理分销系统</p>
        </div>
        <div class="footer_nav_menu">
            <h3>B2C</h3>
            <p @click="changePage('/marketing')">企业官网</p>
            <p @click="changePage('/appMall')">App商城</p>
            <p @click="changePage('/applet')">小程序</p>
        </div>
        <div class="footer_nav_menu">
            <h3>业务中台</h3>
            <p @click="changePage('/crm')">CRM</p>
        </div>
    </div>
    <div class="footer_right">
      <div class="footer_nav_right">
          <h3>联系我们</h3>
          <div class="footer_img_box">
            <span>河北省沧州市运河区解放路颐和大厦19楼</span><br>
            <b>联系电话：18032703250</b>
          </div>
      </div>
    </div>
    <div class="footer_copyright"><p>Copyright © 2014-2020 众博信息 版权所有</p></div>
  </div>
</template>
<script>
export default {
  methods:{
      changePage:function(val){
        this.$router.push(val);
      }
  }
}
</script>
<style scoped>
  .footer{
    width: 100%;
    height: 355px;
    background: #272B2E;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding-top:40px;
    position: relative;
  }
  .footer_nav{
    display: flex;
    justify-content:space-between ;
  }
  .footer_nav_menu{
    width:130px;
  }
  .footer_nav_menu  h3{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 25px;
  }
  .footer_nav_right  h3{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 25px;
  }
  .footer_nav_menu p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .footer_right{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: left;
    margin-right: 30px;
  }
  .footer_img_box b,.footer_img_box span{
    line-height: 28px;
    font-weight: normal;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
  .footer_copyright{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,0); 
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
</style>
<template>
  <div class="nav_header_wrap">
    <div class="nav_header">
      <div class="header_logo">
        <img
          src="../../../assets/image/logo.jpg"
          alt=""
        >
      </div>
      <div class="header_nav">
        <el-menu
          :default-active="$route.path"
          router
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#ff0610"
        >
          <el-menu-item
            index="/index"
            class="router_active"
          >首页</el-menu-item>
          <el-menu-item
            index="/retail"
            class="router_active"
          >新零售</el-menu-item>
          <el-menu-item
            index="/trade"
            class="router_active"
          >外贸建站</el-menu-item>

          <!-- <el-submenu index="2" class="router_active">
              <template slot="title">产品中心</template>
              <div class="nav_child_list">
                <p class="nav_child_title">新零售</p>
                <el-menu-item index="/retail">新零售云店</el-menu-item>
                <el-menu-item index="/broadcast">直播带货</el-menu-item>
                <el-menu-item index="/community">社区团购</el-menu-item>
                <el-menu-item index="/agent">代理分销系统</el-menu-item>
              </div>
              <div class="nav_child_list">
                <p class="nav_child_title">B2C</p>
                <el-menu-item index="/marketing">企业官网</el-menu-item>
                <el-menu-item index="/appMall">App商城</el-menu-item>
                <el-menu-item index="/applet">小程序</el-menu-item>
              </div>
              <div class="nav_child_list">
                <p class="nav_child_title">业务中台</p>
                <el-menu-item index="/crm">CRM</el-menu-item>
              </div>
            </el-submenu> -->
          <el-menu-item index="/appMall" class="router_active">餐饮系统</el-menu-item>
          <el-menu-item
            index="/solution"
            class="router_active"
          >解决方案</el-menu-item>
          <el-menu-item
            index="/aboutus"
            class="router_active"
          >关于我们</el-menu-item>
        </el-menu>
        <!-- <div class="header_nav_list">
          <li
            class="router_active_btn"
            @click="modal1 = true"
          >申请试用</li>
        </div> -->
      </div>
      <div class="silder">
        <a
          href="http://wpa.qq.com/msgrd?v=3&uin=2852837044&site=qq&menu=yes"
          class="customer"
        >
          <img
            src="../../../assets/image/customer.png"
            alt=""
          >
          <span>联系客服</span>
        </a>
        <el-backtop
          class="goTop"
          :right="0"
          :bottom="45"
        >
          <i
            class="el-icon-arrow-up"
            style="font-size: 35px;"
          ></i>
          <span>返回顶部</span>
        </el-backtop>
      </div>
    </div>
    <Modal
      v-model="modal1"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="apply">
        <h3>请您填写以下信息，马上预约演示</h3>
        <div class="apply_form">
          <Input
            v-model="value1"
            size="large"
            placeholder="姓名"
            style="margin-top:20px"
          ></Input>
          <Input
            v-model="value2"
            size="large"
            placeholder="手机号码"
            style="margin-top:20px"
          ></Input>
          <Input
            v-model="value3"
            size="large"
            placeholder="你的职位"
            style="margin-top:20px"
          ></Input>
          <Input
            v-model="value4"
            size="large"
            placeholder="企业名称"
            style="margin:20px 0 10px 0;"
          ></Input>
        </div>
      </div>
    </Modal>
  </div>

</template>
<script>
export default {
  data() {
    return {
      modal1: false,
      value1: "",
      value2: "",
      value3: "",
      value4: ""
    };
  },
  created() {},
  methods: {
    ok() {
      // this.$Message.info('点击了确定');
    },
    cancel() {
      // this.$Message.info('点击了取消');
    },
    onMouseoverEnvDelBtn(event) {
      event.target.parentElement.querySelector(".tab_goToPage").style.cssText +=
        "display:flex";
    },
    onMouseleaveEnvDelBtn(event) {
      event.target.parentElement.querySelector(".tab_goToPage").style.cssText +=
        "display:none";
    }
  }
};
</script>
<style scoped>
.nav_header_wrap {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 9;
}
.nav_header {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  /* padding: 0 80px; */
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  /* font-weight: 600; */
  color: #06172c;
  box-sizing: border-box;
  height: 80px;
  line-height: 80px;
  margin: auto;
}
.header_logo {
  font-size: 23px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #4a4a4a;
  display: flex;
  align-items: center;
}
.header_logo img {
  height: 40px;
}
.header_nav {
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
}
.header_nav_list {
  display: flex;
  align-items: center;
}
.router_active {
  font-size: 14px;
  height: 80px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /* color: #1042CD;
    border-bottom: 4px solid #1042CD; */
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 0 25px;
  float: left;
}
li.router_active_btn {
  font-size: 14px;
  height: 80px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /* color: #1042CD;
    border-bottom: 4px solid #1042CD; */
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 0 20px;
  float: left;
  background: #ff0610;
  border-radius: 2px;
  color: #fff;
  height: 40px;
  line-height: 40px;
  margin-left: 25px;
}
li.router_active_btn:hover {
  box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.8);
}
.border_hr {
  width: 1px;
  display: block;
  float: left;
  background: #eaeaea;
  height: 28px;
}
.apply {
  padding: 0 50px;
}
.apply h3 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 20px 0 20px 0;
}
#showTab {
  position: relative;
}
.tab_goToPage {
  width: 600px;
  height: 268px;
  border: 1px solid red;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 2;
  background: #fff;
  display: flex;
  justify-content: space-around;
  padding-top: 25px;
  display: none;
}

.tab_goToPage_list h4 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9d9d9d;
  height: 36px;
  text-align: left;
  border-bottom: 1px solid #f1f1f1;
  line-height: 36px;
  margin-bottom: 15px;
}
.silder {
  position: fixed;
  right: 0;
  bottom: 110px;
  z-index: 9;
}
.silder .customer {
  margin-bottom: 8px;
}
.silder .customer,
.silder .goTop {
  width: 64px;
  height: 64px;
  background: #ff0610;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 15px;
  cursor: pointer;
}
.silder .customer img {
  width: 28px;
  height: 28px;
}
.silder .goTop img {
  width: 20px;
  height: 20px;
}
.el-menu--horizontal > .el-menu-item {
  height: 80px;
  line-height: 80px;
  color: #333;
  font-size: 16px;
}
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 80px;
  line-height: 80px;
  border-bottom: none;
  color: #333;
  font-size: 16px;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  text-align: center;
}

.el-menu--horizontal > .el-menu-item.is-active {
  font-weight: 900;
  color: #ff0610;
  border: none;
}

/* /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: none;
    text-align: center;
    font-weight: 900;
    color: #FF0610;
  } */
.nav_child_list {
  width: 100px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.nav_child_title {
  width: 80px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9d9d9d;
  line-height: 30px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #000000;
  padding: 0;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #ff0610;
}
</style>
<style>
.el-menu--popup {
  width: 600px;
  padding: 25px 0 !important;
  display: flex;
  justify-content: space-between;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:hover {
  background: none;
}
</style>

<template>
  <div class="main-container">
    <headercontent></headercontent>
    <router-view></router-view>
    <footercontent></footercontent>
  </div>
</template>

<script>
import Headercontent from './components/header'
import Footercontent from './components/footer'

export default {
  name: 'layout',
  components: {
    Headercontent,
    Footercontent
  }
}
</script>
<style scoped>
.main-container {
  background-color: #f7f7f7
}
</style>

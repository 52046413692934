import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '../views/layout/Layout'

const routerData = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
      path: 'index',
      component: () => import('@/views/index/index')
    },{
      path: 'retail',
      component: () => import('@/views/retail/index')
    }, {
      path: 'aboutus',
      component: () => import('@/views/aboutus/index')
    },{
      path: 'broadcast',
      component: () => import('@/views/broadcast/index')
    },{
      path: 'community',
      component: () => import('@/views/community/index')
    },{
      path: 'agent',
      component: () => import('@/views/agent/index')
    },{
      path: 'marketing',
      component: () => import('@/views/marketing/index')
    },{
      path: 'appMall',
      component: () => import('@/views/appMall/index')
    },{
      path: 'aboutus',
      component: () => import('@/views/aboutus/index')
    },{
      path: 'solution',
      component: () => import('@/views/solution/index')
    },{
      path: 'applet',
      component: () => import('@/views/applet/index')
    },{
      path: 'crm',
      component: () => import('@/views/CRM/index')
    },{
      path: 'trade',
      // redirect: 'noredirect',
      component: () => import('@/views/trade/index')
    }]
  }
]

export default new Router({
  // mode: 'history',
  scrollBehavior: () => ({y: 0}),
  routes: routerData
})